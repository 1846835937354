import React from 'react'

import Layout from '../components/layout'
import Listing from '../components/listing'
import styled from 'styled-components'

const BlogHeader = styled.h2`
  margin: 1.5rem 0 2rem !important;
  color: rgba(255, 255, 255, 0.8);
`

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <BlogHeader className="home-title--level-2">Latest Blog Posts</BlogHeader>
    <Listing />
  </Layout>
)

export default IndexPage
