import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// import Image from '../components/image'

const LISTING_QUERY = graphql`
  query BlogPostListing {
    allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/posts/.*.md$/" } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

const Post = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 3px 10px rgba(25, 17, 34, 0.1);
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.95);

  h3 {
    margin-bottom: 0;
    margin-top: 0;
  }
  a {
    &.read-more {
      align-self: flex-end;
      display: inline-block;
      padding: 1rem 2rem;
      text-decoration: none;
      font-size: 1rem;
      line-height: 1;
      color: white;
      background: #1ba39c;
      border-radius: 3rem;
      transform: translate3d(0, 0, 0);
      transition: all 0.3s ease;

      @media (hover: hover) {
        &:hover {
          background: #097771;
          color: white;
          transform: translate3d(0, -2px, 0);
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  p {
    &.date {
      margin-top: 0.3rem;
      font-family: var(--sans-serif);
      font-size: 0.8rem;
      color: #888;
    }
  }
`

const Listing = () => (
  <>
    <StaticQuery
      query={LISTING_QUERY}
      render={({ allMarkdownRemark }) =>
        allMarkdownRemark.edges.map(({ node }) => (
          <Post key={node.frontmatter.slug}>
            <Link to={`/posts${node.frontmatter.slug}`}>
              <h3>{node.frontmatter.title}</h3>
            </Link>
            <p className="date">{node.frontmatter.date}</p>
            <p>{node.excerpt}</p>
            <Link className="read-more" to={`/posts${node.frontmatter.slug}`}>
              Read More &rarr;
            </Link>
          </Post>
        ))
      }
    />
  </>
)

export default Listing
